<template>
  <div class='box-tmp'>
    <div v-for='i in 20' :key='i' class='box-row'>
      <div v-for='j in 20' :key='`${i}_${j}`' class='box-col'>
        <span class='number'>{{ (i * j) % 2}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeavyComp'
}
</script>

<style scoped>
.box-tmp {
    border: #f0c78a 1px dashed;
    width: 340px;
    height: 340px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    overflow-y: hidden;
    gap: 1px;
}
.box-row{
    border-bottom: #f2f2f2f2 1px solid;
    margin-bottom: 1px!important;
    line-height: 14px;
}
.box-col{
    border: #f2f2f2f2 1px solid;
    display: inline-block;
    width: 15px;
}
.number {
    font-size: 12px;
    border: #c43e3e 1px dashed;
    padding: 2px;
}
</style>
