//逐帧渲染：渲染时长不会减少（总时长应该会略微长），但是用户看到内容密集页面的白屏时间减少，提升用户体验

export function useDefer(maxFrameCount = 1000) {
  let frameCount = 0
  // eslint-disable-next-line no-unused-vars
  const refreshFrameCount = () => {
    //渲染帧：1秒60次
    requestAnimationFrame(() => {
      frameCount++
      if (frameCount < maxFrameCount) {
        refreshFrameCount()
      }
    })
  }

  refreshFrameCount()

  return function(showInFrameCount) {
    return frameCount >= showInFrameCount
  }

}
