<template>
  <div>
    <router-view v-show='$route.matched.length==3'></router-view>
    <PageHeaderLayout v-show='$route.matched.length==2'>
      <div class='main-page-content'>
        <div>
          <el-row :gutter='12'>
            <el-col :lg='8' :md='12' :sm='24' v-for='(menu ,k) in menus' :key='k'>
              <el-card shadow='hover' class='box-card' v-if='userPermissions.indexOf(menu.permission_name) !== -1'>
                <el-link type='primary' @click='goToPage(menu.url)'>
                  <i :class='`iconfont ${menu.icon}`'></i>
                  &nbsp;&nbsp;{{ menu.display_name }}&nbsp;&nbsp;
                </el-link>
              </el-card>
            </el-col>
          </el-row>
        </div>
<!--        <div>使用defer之前：内存使用：1.1G~1.2G 渲染：1293毫秒（总 3330毫秒）</div>-->
<!--        <div class='container'>-->

<!--          <div v-for='n in 200' :key='n'>-->
<!--            <HeavyComp v-if='defer(n)'></HeavyComp>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </PageHeaderLayout>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import { mapGetters } from 'vuex'
import HeavyComp from '@/pages/system/HeavyComp.vue'
import { useDefer } from '@/utils/useDefer'

export default {
  name: 'MonitorIndex',
  components: {
    HeavyComp,
    PageHeaderLayout
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType'])
  },
  data() {
    return {
      menus: [
        {
          icon: 'icon-server-jiankong',
          display_name: '服务监控',
          url: '/monitor/server',
          permission_name: 'monitor_server'
        },
        { icon: 'el-icon-aim', display_name: '缓存监控', url: '/monitor/cache', permission_name: 'monitor_cache' },
        { icon: 'el-icon-time', display_name: '定时任务', url: '/monitor/crontab', permission_name: 'monitor_crontab' }
      ]
    }
  },
  methods: {
    goToPage(routeName) {
      this.$router.push({ path: routeName })
    },
    defer(n) {
      return useDefer(n)
    }
  }
}
</script>

<style scoped>
.container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1em;
}
</style>
